<template>
  <div class="auth-layout">
    <Nuxt />

    <div v-if="$nuxt.isOffline" class="offline-bar">
      <div>Právě jste offline.</div>
      <div>Některé části stránky nemusí fungovat.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutAuth',
  auth: 'guest',
  head: () => ({
    // title: 'Úvod',
  }),
  async created () {
    if (this.$auth.loggedIn) {
      await this.$laravel.setPermissions(this.$auth.user.permissions)
      await this.$laravel.setRoles(this.$auth.user.roles)
    } else {
      await this.$laravel.setPermissions([])
      await this.$laravel.setRoles([])
    }
  }
}
</script>
