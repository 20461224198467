import Vue from 'vue'

// import { Notification } from 'element-ui'
import Notification from 'element-ui/lib/notification'

import lang from 'element-ui/lib/locale/lang/cs-CZ'
import locale from 'element-ui/lib/locale'

export default function (context) {
  locale.use(lang)

  Vue.prototype.$notify = Notification
  context.$notify = Notification
}
