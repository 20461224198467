<template>
  <div v-if="error">
    <b-container>
      <b-row>
        <b-col class="intro-text">
          <h2>Stránku jsme nenašli</h2>
          <div>
            <b-button
              to="/"
              variant="primary"
              class="mt-3 mt-xl-4 mx-2"
            >
              Návrat domů
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'PageError',
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  head: () => ({
    title: 'Chyba',
    bodyAttrs: {
      id: 'error-page'
    }
  }),
  async created () {
    if (this.$auth.loggedIn) {
      await this.$laravel.setPermissions(this.$auth.user.permissions)
      await this.$laravel.setRoles(this.$auth.user.roles)
    } else {
      await this.$laravel.setPermissions([])
      await this.$laravel.setRoles([])
    }
  }
}
</script>
