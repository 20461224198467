// import https from 'https'

export default function ({ $axios, $notify }) {
  // if (isDev) { $axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false }) }

  $axios.onError((error) => {
    $notify({
      message: error.response?.data?.message || 'Chyba',
      timeout: 2500,
      icon: 'fa fa-times',
      type: 'error'
    })

    throw error
  })
  $axios.onResponse((response) => {
    if (response?.data?.message) {
      $notify({
        message: response?.data?.message,
        timeout: 2500,
        icon: 'fa fa-check',
        type: 'success'
      })
    }
  })
}
